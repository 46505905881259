import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { IUser } from "./User";
import moment from 'moment';

export interface INews {
    id?: number,
    title?: string,
    attachment?: string,
    supplier_id?: number,
    published_at?: string,
    user?: IUser,
}


export class News extends DataModel<INews> {

    public resourceName = "news";
    public resourceNamePlural = "news";

    private _id: number;
    private _title: string;
    private _attachment: string;
    private _supplier_id: number;
    private _published_at: string;
    private _user: IUser;

    protected getDefaultValues(): INews {
        return {
            user: {}
        };
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<INews> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<INews> {
        return new DataModelValidator<INews>({
            title: [new RequiredValidator(), new LengthValidator({ min: 6 })],
            supplier_id: [new RequiredValidator()],
            attachment: [ new LengthValidator({max: 255})],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<INews>, action: AnyAction): IDataModelState<INews> {
        return state;
    }

    public loadSuppliers(): Promise<INews[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }


    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<INews | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
        .plainPostOperation({
            title: this._title,
            attachment: this._attachment,
            supplier_id: Number(this._supplier_id),
            published_at: moment().format("YYYY-MM-DD")
        });
    }

    public update(data: INews): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                title: data.title,
                attachment: data.attachment,
                supplier_id: Number(data.supplier_id),
                published_at: data.published_at
            });
    }



    getStoreKey(): string {
        return "NEWS";
    }

    protected setFromObj(data: INews): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._title = DataModel.safeGet(data.title, this._title);
        this._attachment = DataModel.safeGet(data.attachment, this._attachment);
        this._supplier_id = DataModel.safeGet(data.supplier_id, this._supplier_id);
        this._published_at = DataModel.safeGet(data.published_at, this._published_at)
        this._user = DataModel.safeGet(data.user, this._user);
    }


    protected toObj(): INews {
        return {
            id: this._id,
            title: this._title,
            attachment: this._attachment,
            supplier_id: this._supplier_id,
            published_at: this._published_at,
        };
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    get attachment() {
        return this._attachment;
    }

    set attachment(path: string) {
        this._attachment = path;
    }

    get supplier_id() {
        return this._supplier_id;
    }

    get published_at() {
        return this._published_at;
    }

}
