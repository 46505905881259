import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, ModalFooter, Label, CustomInput, Spinner } from 'reactstrap';
import Select from 'react-select';
import { reactSelectStyle } from '../../assets/css/reactSelectStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faMapMarkerAlt, faLink, faSleigh, } from '@fortawesome/free-solid-svg-icons';
import { faFile as faFileSupplier } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import Util from '../custom/Util';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { DropdownInput } from '../../common/components/widgets/form/input/DropdownInput';
import PopoverItem from '../custom/PopoverItem';
import { DateInput } from '../../common/components/widgets/form/input/DateInput';
import { Supplier, ISupplier } from '../models/Supplier';
import { Mill as MillModel, IMill } from '../models/Mill';
import Mill from './supplier/Mill';
import { AxiosResponse, AxiosError } from 'axios';
import { SupplierContact, ISupplierContact } from '../models/SupplierContact';
import { SupplierClassification } from '../models/SupplierClassification';
import { Country } from '../models/Country';
import { FormHandlingUtil } from '../../common/components/widgets/form/util/FormHandlingUtil';
import { QualityStatus } from '../models/QualityStatus';
import { File as FileModel } from '../models/File';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';


@withRouter(Path.SUPPLIER_SINGLE.toString(), PageContainer)
@withRouter(Path.SUPPLIER_MILL.toString(), PageContainer)

@withSecurity([Role.USER, Role.ADMIN, Role.CATEGORY_MANAGER], Identity, Path.LOGIN)
export default class SupplierPage extends VlbPage {

    private supplier = new Supplier();
    private supplierContact = new SupplierContact();
    private mill = new MillModel();
    private supplierClassification = new SupplierClassification();
    private country = new Country();
    private qualityStatus = new QualityStatus();
    private submitted = false;
    private contactEdit = false;
    private file = new FileModel();


    private millInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        country_id: React.createRef(),
        city: React.createRef(),
        quality_status_id: React.createRef(),
        type: React.createRef(),
        aml_class: React.createRef(),
        aml_since: React.createRef()
    };

    private contactInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        job_title: React.createRef(),
        email: React.createRef(),
        sales_office: React.createRef(),
        mobile_phone: React.createRef(),
        phone: React.createRef(),
        supplier_id: React.createRef(),
    };

    private supplierInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        supplier_classification_id: React.createRef(), 
        country_id: React.createRef(),
        city: React.createRef(),
        zip_code: React.createRef(),
        address: React.createRef(),
        website: React.createRef(),
        phone_number: React.createRef(),
        framework_agreement_date: React.createRef(),
        framework_agreement_attachment: React.createRef(),
        bonus_agreement_date: React.createRef(),
        bonus_agreement_attachment: React.createRef(),
        code_of_conduct: React.createRef(),
    };

    private formHandlingUtilMill: FormHandlingUtil;
    private formHandlingUtilContact: FormHandlingUtil;

    constructor(props: any) {
        super(props);

        this.handleSelectMill = this.handleSelectMill.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.setInputValues = this.setInputValues.bind(this);
        this.validateContact = this.validateContact.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.handleSubmitSupplier = this.handleSubmitSupplier.bind(this);
        this.handleSelectMill = this.handleSelectMill.bind(this);
        this.loadData = this.loadData.bind(this);
        this.addFile = this.addFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.updateSupplier = this.updateSupplier.bind(this);
        this.fileView = this.fileView.bind(this);

        let mill_id = this.props.match.params.millid ? this.props.match.params.millid : null;


        this.state = {
            selectMill: null,
            modalAdd: false,
            currentModalAdd: null,
            modalEdit: false,
            currentModalEdit: null,
            modalDelete: false,
            currentModelDelete: null,
            tableName: null,
            supplierId: null,
            supplierContactList: [],
            loaded: false,
            mill_id,
            selectedMill: null,
            options: [],
            supplier_classifications: [],
            countries: [],
            qStatuses: [],
            files: { bonus_agreement_attachment: null, framework_agreement_attachment: null, code_of_conduct: null},
            fileUrls: [],

            modalFile: false,
            currentModelFile: {},

            modalFileDelete: false,
            modalFileDeleteAttr: "",
            modalFileDeleteEvent: null,

            waitForUpload: false
        };

        this.formHandlingUtilMill = new FormHandlingUtil(
            this.millInputs,
            this.handleSubmitMill.bind(this),
            [this.mill]
        );

        this.formHandlingUtilContact = new FormHandlingUtil(
            this.contactInputs,
            this.handleSubmitContact.bind(this),
            [this.supplierContact]
        );
    }

    pageTitle() {
        return this.supplier.name + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
            this.supplier
        ];
    }

    private readInputsContact(): any {

        const plainObject: ISupplierContact = {};
        let inputs = this.contactInputs;

        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        this.supplierContact.setFromPlainObject({ ...this.supplierContact.asPlainObject(), ...plainObject });

        return plainObject;
    }

    private readInputsSupplier(): ISupplier {

        const plainObject: ISupplier = {};
        let inputs = this.supplierInputs;
        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        this.supplier.setFromPlainObject({ ...this.supplier.asPlainObject(), ...plainObject });

        return plainObject;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }


    protected handleSubmitMill(): void {

        this.mill.supplier_id = this.props.match.params.id;
        this.mill.createNew().then((response: IMill) => {
            Util.notification("success", "Mill saved sucessfully", 1500);
            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false })
            this.loadData()
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.formHandlingUtilMill.getFillInputsWithErrorsHandle()(errors);
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }

    protected handleSubmitSupplier(event: React.MouseEvent): void {
        this.submitted = true;

        if (this.validateSupplier(event)) {
            if (this.state.files["framework_agreement_attachment"] || this.state.files["bonus_agreement_attachment"] || this.state.files["code_of_conduct"]) {
                this.uploadFilesToServer();

                this.setState({
                    waitForUpload: true
                })

                setTimeout(() => {
                    this.updateSupplier()
                }, 2000)
            } else {
                this.updateSupplier()
            }
        }

        this.submitted = false;
    }

    updateSupplier() {
        this.supplier.update(this.supplier.asPlainObject()).then((response: AxiosResponse) => {
            Util.notification("success", "Supplier updated saved sucessfully", 1500);


            this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false })
            this.loadData()
        }).catch((error: any) => {
            switch (error.response.status) {
                case 422:
                    if (error.response.data.errors) {
                        const respErrors: any[] = error.response.data.errors;
                        const errors: { [attr: string]: string } = {};

                        respErrors.forEach((error: any) => {
                            errors[error.fieldName] = error.errorMessage;
                        });

                        this.fillInputsWithErrors(errors, this.supplierInputs);
                    }
            }

            this.setState({ waitForUpload: false })
        });
    }

    validateSupplier(event: any): boolean {
        this.readInputsSupplier();

        let inputs = this.supplierInputs;

        const valid = this.supplier.validate();

        if (this.submitted) {
            if (!valid) {
                this.setState({ formError: [this.state.formStep] })
                const errors: { [attr: string]: string } = this.supplier.getErrors();
                this.fillInputsWithErrors(errors, inputs);
            }
        }

        event.preventDefault();

        return valid;

    }

    validateContact(event: any): boolean {
        this.readInputsContact();

        let inputs: any;

        this.setState({
            waitForUpload: true
        })


        const valid = this.supplierContact.validate();

        if (this.submitted) {
            if (!valid) {
                this.setState({ formError: [this.state.formStep] })
                const errors: { [attr: string]: string } = this.supplierContact.getErrors();
                this.fillInputsWithErrors(errors, inputs);
            }
        }

        event.preventDefault();

        return valid;

    }


    removeFile(attr: string, event: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement

        inputElement.value = ''

        this.supplier[attr] = null;
        this.supplier.update(this.supplier).then((response: AxiosResponse) => {
            Util.notification("success", "Supplier successfully updated.", 10000);
            this.setState({
                modalFileDelete: false,
                reload: true,
            })
        });

    }

    uploadFilesToServer() {
        let data = new FormData();

        //Append files to form data
        let files = this.state.files;

        if (files.bonus_agreement_attachment) {
            data.append('files', files.bonus_agreement_attachment, files.bonus_agreement_attachment.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;
                this.supplier.bonus_agreement_attachment = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, bonus_agreement_attachment: filesResponse[0] }
                })

            })
        }


        if (files.framework_agreement_attachment) {
            data.append('files', files.framework_agreement_attachment, files.framework_agreement_attachment.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;

                this.supplier.framework_agreement_attachment = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, framework_agreement_attachment: filesResponse[0] }
                })

            })
        }

        if (files.code_of_conduct) {
            data.append('files', files.code_of_conduct, files.code_of_conduct.name);

            this.file.createNew(data).then((response: AxiosResponse) => {
                let filesResponse: string[] = response.data;

                this.supplier.code_of_conduct = filesResponse[0];
                this.setState({
                    fileUrls: { ...this.state.fileUrls, code_of_conduct: filesResponse[0] }
                })

            })
        }
    }

    addFile = (attr: string, event) => {

        let newFilesObj = { bonus_agreement_attachment: null, framework_agreement_attachment: null, code_of_conduct: null};
        let files = event.target.files;

        for (var i = 0; i < files.length; i++) {
            let fileGood = true;
            var regex = new RegExp("(.*?)\.(pdf)$");

            if (!(regex.test(files[i].type))) {
                Util.notification("error", "Document format is not valid. Allowed: pdf", 10000);
                fileGood = false;
            }

            if (fileGood) {
                newFilesObj[attr] = files[i];
                this.supplierInputs[attr].current.setValue(files[i].name)
            }
        }

        this.setState({ files: { ...this.state.files, ...newFilesObj } })

    }

    protected handleSubmitContact(event: any): void {

        let promise: Promise<AxiosResponse | ISupplierContact>;
        this.submitted = true;

        this.supplierContact.supplier_id = this.props.match.params.id;
        if (this.contactEdit) {
            promise = this.supplierContact.update(this.supplierContact.asPlainObject());

        } else {
            promise = this.supplierContact.createNew();
        }

        if (this.validateContact(event)) {
            promise.then((response: ISupplierContact) => {
                Util.notification("success", "Supplier contact updated saved sucessfully", 1500);
                this.contactEdit = false;
                this.setState({ modalAdd: false, modalEdit: false, waitForUpload: false })
                this.loadData()
            }).catch((error: any) => {
                switch (error.response.status) {
                    case 422:
                        if (error.response.data.errors) {
                            const respErrors: any[] = error.response.data.errors;
                            const errors: { [attr: string]: string } = {};

                            respErrors.forEach((error: any) => {
                                errors[error.fieldName] = error.errorMessage;
                            });


                            this.fillInputsWithErrors(errors, this.contactInputs);
                        }
                }

                this.setState({ waitForUpload: false })
            });
        }

        this.submitted = false;
    }

    setInputValues(values: any, inputs) {

        for (let key in values) {

            if (inputs[key] && inputs[key].current) {
                inputs[key].current.setValue(values[key]);

            }
        }
    }

    loadData() {

        this.country.getListPlain({
            sort: {
                attr: "name",
                ascending: true
            }
        })
            .then((response: AxiosResponse) => {

                this.setState({
                    countries: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Countries cannot be loaded", 1500);
            });

        this.supplierClassification.getListPlain({
            sort: {
                attr: "name",
                ascending: true
            }
        })
            .then((response: AxiosResponse) => {

                this.setState({
                    supplier_classifications: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Countries cannot be loaded", 1500);
            });


        this.qualityStatus.getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    qStatuses: response.data
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Countries cannot be loaded", 1500);
            });

        this.supplierContact.loadBySupplierId(this.props.match.params.id)
            .then((response: ISupplierContact[]) => {

                this.setState({
                    supplierContactList: response
                });

            }).catch((e: AxiosError) => {
                Util.notification("error", "Supplier contacts cannot be loaded", 1500);
            });

        this.mill.loadMills(",supplier_id:eq:" + this.props.match.params.id)
            .then((response: IMill[]) => {

                if (response.length > 0) {
                    let options = response.map(single => (
                        { value: single.id, label: single.name }
                    ))

                    let defaultValue = options.find(single => single.value === +this.props.match.params.millid)

                    this.setState({
                        options,
                        selectedMill: defaultValue
                    })

                }

            }).catch((e: AxiosError) => {
                Util.notification("error", "Mills cannot be loaded", 1500);
            });

        return this.supplier.loadById(this.props.match.params.id)
            .then((response: ISupplier | any) => {

                const aError: any = response as AxiosError;
                const supplier: ISupplier = response as ISupplier;

                if (!aError.response) {
                    this.supplier.setFromPlainObject(response as ISupplier);
                    this.setState({ loaded: true })

                } else {
                    window.location.replace(Path.NOTFOUND);
                }

            })
    }

    componentDidMount() {

        //Mill form handling util
        this.formHandlingUtilMill.setToModelsState();
        this.formHandlingUtilMill.activateReturnButtonSubmit();

        //Contact form handling util
        this.formHandlingUtilContact.setToModelsState();
        this.formHandlingUtilContact.activateReturnButtonSubmit();

        this.loadData().then(() => super.componentDidMount())

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.millid && this.state.mill_id && prevProps.match.params.millid !== this.state.mill_id) {
            window.history.replaceState({}, null, "/supplier/" + prevProps.match.params.id + "/mill/" + this.state.mill_id + "/");
        }
    }

    handleSelectMill(selected) {

        window.history.replaceState({}, null, "/supplier/" + this.props.match.params.id + "/mill/" + selected.value + "/");

        this.setState({
            selectedMill: selected,
            mill_id: selected.value
        })
    }

    add(modelAdd?, tablename?) {
        this.setState(prevState => ({
            modalAdd: !prevState.modalAdd,
            currentModelAdd: modelAdd,
            tableName: tablename
        }));
    }

    edit(modelEdit?, tablename?) {

        switch (tablename) {
            case "suppliercontact":

                this.contactEdit = true;

                this.supplierContact.loadById(modelEdit.id)
                    .then((response: ISupplierContact) => {
                        this.supplierContact.setFromPlainObject(response)
                        this.setInputValues(response, this.contactInputs)


                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Supplier contacts cannot be loaded", 1500);
                    });
                break;
            case "supplierinfo":

                this.supplier.loadById(this.props.match.params.id)
                    .then((response: ISupplier) => {
                        this.supplier.setFromPlainObject(response)

                        this.setInputValues(response, this.supplierInputs);

                    }).catch((e: AxiosError) => {
                        Util.notification("error", "Supplier info cannot be loaded", 1500);
                    });

                break;
        };


        this.setState(prevState => ({
            modalEdit: !prevState.modalEdit,
            currentModalEdit: modelEdit,
            tableName: tablename
        }));
    }

    toggleDelete(modelDelete?, tablename?) {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete,
            currentModelDelete: modelDelete,
            tableName: tablename
        }));
    }

    delete() {

        let id = this.state.currentModelDelete.id;
        switch (this.state.tableName) {
            case "suppliercontact":
                this.supplierContact.del(id)
                    .then(() => {
                        Util.notification("success", "Supplier contact successfully deleted.", 1500);
                        document.location.reload();
                    })
                    .catch(() => {
                        Util.notification("error", "Could not delete supplier contact.", 1500);
                    });
                break;
            case "supplierinfo":
                this.supplier.del(this.props.match.params.id)
                    .then(() => {
                        Util.notification("success", "Supplier successfully deleted.", 1500);
                        document.location.pathname = "suppliers";
                    })
                    .catch(() => {
                        Util.notification("error", "Could not delete supplier.", 1500);
                    })

        }

    }

    fileDelete(attr: string, event: React.MouseEvent<HTMLElement>) {
        const inputElement: HTMLInputElement = document.getElementById(attr) as HTMLInputElement

        if (inputElement.value === "") {
            Util.notification("info", "No file to delete", 1500);
        } else {
            this.setState(prevState => ({
                modalFileDelete: !prevState.modalFileDelete,
                modalFileDeleteAttr: attr,
                modalFileDeleteEvent: event,
            }));
        }
    }

    fileView(filename?: any, title?: string) {
        if (filename) {
            this.setState(prevState => ({
                modalFile: !prevState.modalFile,
                currentModelFile: { file: filename, title: title }
            }));
        } else {
            Util.notification("info", "No attachment", 1500)
        }

    }

    copyText(name, content) {
        Util.copyToClipboard(content)
        Util.notification("success", name + ' is copied', 1500);
    }

    renderContent() {

        const titleLoader = <i className="title-loader mw-title" />;
        const tableLoader = <i className="content-loader w-75" />;

        const supplierInfoList = {
            name: this.supplier.name,
            supplier_classification: this.supplier.supplier_classification ? this.supplier.supplier_classification.name : null,
            country: this.supplier.country ? this.supplier.country.name : null,
            city: this.supplier.city,
            zipcode: this.supplier.zip_code,
            address: this.supplier.address,
            website: this.supplier.website,
            telephone: this.supplier.phone_number,
            manager: this.supplier.manager ? this.supplier.manager.full_name : null,
            framework_agreement_date: this.supplier.framework_agreement_date,
            framework_agreement_attachment: this.supplier.framework_agreement_attachment,
            bonus_agreement_date: this.supplier.bonus_agreement_date,
            bonus_agreement_attachment: this.supplier.bonus_agreement_attachment,
            code_of_conduct: this.supplier.code_of_conduct,
        }

        let supplierInfo;
        supplierInfo = (
            <>
                <tr onDoubleClick={() => this.copyText('Name', supplierInfoList.name)}>
                    <th>Name</th>
                    <td>{supplierInfoList.name !== undefined ? supplierInfoList.name : tableLoader}
                        {supplierInfoList.website ?
                            <PopoverItem key={1} item={{ placement: 'top' }} icon={faLink} id="website" titleP="Website" contentP={supplierInfoList.website} isLink={true}
                            />
                            : null

                        }

                    </td>
                </tr>
                <tr onDoubleClick={() => this.copyText('Country', supplierInfoList.country)}>
                    <th>Country</th>
                    <td>{supplierInfoList.country !== null ? supplierInfoList.country : tableLoader}</td>
                </tr>
                <tr onDoubleClick={() => this.copyText('City', supplierInfoList.city)}>
                    <th>City</th>
                    <td>{supplierInfoList.city !== undefined ? supplierInfoList.city : tableLoader}
                        <PopoverItem key={1} item={{ placement: 'top' }}
                            icon={faMapMarkerAlt}
                            id="city" titleP="Zipcode & Address"
                            contentP={supplierInfoList.zipcode + ", " + supplierInfoList.address} /></td>
                </tr>
                <tr onDoubleClick={() => this.copyText('Telephone', supplierInfoList.telephone)}>
                    <th>Telephone</th>
                    <td>{supplierInfoList.telephone !== undefined ? supplierInfoList.telephone : tableLoader}</td>
                </tr>
                <tr onDoubleClick={() => this.copyText('Van Leeuwen Manager', supplierInfoList.manager)}>
                    <th>Van Leeuwen Manager</th>
                    <td>{supplierInfoList.manager !== null ? supplierInfoList.manager : tableLoader}</td>
                </tr>
                <tr onDoubleClick={() => this.copyText('Classification', supplierInfoList.supplier_classification)}>
                    <th>Classification</th>
                    <td>{supplierInfoList.supplier_classification !== null ? supplierInfoList.supplier_classification : tableLoader}</td>
                </tr>
                <tr>
                    <th>FRAMEWORK AGM</th>
                    <td>
                        {supplierInfoList.framework_agreement_attachment ?
                            <Link to="#" onClick={() => this.fileView(supplierInfoList.framework_agreement_attachment, 'FRAMEWORK AGM')} className="pr-2"><FontAwesomeIcon icon={faFileSupplier} /></Link>
                            : null
                        }

                        {supplierInfoList.framework_agreement_date}
                    </td>
                </tr>
                <tr>
                    <th>BONUS AGM</th>
                    <td>
                        {supplierInfoList.bonus_agreement_attachment ?
                            <Link to="#" onClick={() => this.fileView(supplierInfoList.bonus_agreement_attachment, 'BONUS AGM')} className="pr-2"><FontAwesomeIcon icon={faFileSupplier} /></Link>
                            : null}

                        {supplierInfoList.bonus_agreement_date}
                    </td>
                </tr>
                <tr>
                    <th>Code of Conduct</th>
                    <td>
                        {supplierInfoList.code_of_conduct ?
                            <Link to="#" onClick={() => this.fileView(supplierInfoList.code_of_conduct, 'Code of Conduct')} className="pr-2"><FontAwesomeIcon icon={faFileSupplier} /></Link>
                            : null
                        }
                    </td>
                </tr>
            </>
        )

        let supplierContact;
        if (Identity.id === this.supplier.manager_id) {
            supplierContact = (
                this.state.supplierContactList.length ? this.state.supplierContactList.map(single => (
                    <tr key={single.id}>
                        <td>{single.name}</td>
                        <td>{single.job_title}</td>
                        <td>{single.email}</td>
                        <td>{single.sales_office}</td>
                        <td>{single.mobile_phone}</td>
                        <td>{single.phone}</td>
                        <td>
                            <Link onClick={() => this.edit(single, 'suppliercontact')} to={window.location} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link>
                            <Link to={window.location} onClick={() => this.toggleDelete(single, 'suppliercontact')} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link>
                        </td>
                    </tr >
                )) : <tr><td colSpan={7} className="font-weight-bold">No results.</td></tr>
            )
        } else {
            if (this.state.loaded) {
                supplierContact = (
                    this.state.supplierContactList.length ? this.state.supplierContactList.map(single => (
                        <tr key={single.id}>
                            <td>{single.name}</td>
                            <td>{single.job_title}</td>
                            <td>{single.email}</td>
                            <td>{single.sales_office}</td>
                            <td>{single.mobile_phone}</td>
                            <td>{single.phone}</td>
                            <td></td>
                        </tr >
                    )) : <tr><td colSpan={7} className="font-weight-bold">No results.</td></tr>
                )
            } else {
                supplierContact = Util.tableLoader(7, 5);
            }
        }

        let editModal;
        if (this.state.tableName === 'suppliercontact') {
            let contactSaveButton;
            if (!this.state.waitForUpload) {
                contactSaveButton = <Button outline color="primary" onClick={this.formHandlingUtilContact.getSubmitHandle()}>Save</Button>
            } else {
                contactSaveButton = <Button outline color="primary" disabled><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>{this.state.currentModalEdit ? this.state.currentModalEdit.name : ''}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="name"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Job Title"
                                    id="jobtitle"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.job_title}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="E-mail"
                                    id="email"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.email}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Mobile phone"
                                    id="mobilephone"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.mobile_phone}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Sales office"
                                    id="salesoffice"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.sales_office}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Landline"
                                    id="landline"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.phone}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={() => this.setState(prevState => ({
                                modalEdit: !prevState.modalEdit,
                                currentModalEdit: false,
                                tableName: "suppliercontact"
                            }))}>Cancel</Button>
                            {contactSaveButton}
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'supplierinfo') {
            let infoSaveButton;
            if (!this.state.waitForUpload) {
                infoSaveButton = (<Button outline color="primary" onClick={this.handleSubmitSupplier}>Save</Button>)
            } else {
                infoSaveButton = (<Button outline color="primary" disabled><Spinner color="secondary" size="sm" className="sppiner-button" /> Save</Button>)
            }
            editModal = (
                <Modal centered size="md" isOpen={this.state.modalEdit} fade={false} toggle={this.edit}>
                    <ModalHeader toggle={this.edit}>Edit supplier info</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="Name"
                                    ref={this.supplierInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={this.state.supplier_classifications}

                                    label="Classification"
                                    id="SupplierClassification"
                                    ref={this.supplierInputs.supplier_classification_id}
                                />

                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={this.state.countries}

                                    label="Country"
                                    id="Country"
                                    ref={this.supplierInputs.country_id}
                                />

                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="City"
                                    id="City"
                                    ref={this.supplierInputs.city}
                                />
                            </FormGroup>
                            <Row>
                                <Col xl="4">
                                    <FormGroup>
                                        <TextInput
                                            label="Zipcode"
                                            id="Zipcode"
                                            ref={this.supplierInputs.zip_code}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl="8">
                                    <FormGroup>
                                        <TextInput
                                            label="Address"
                                            id="Address"
                                            ref={this.supplierInputs.address}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <TextInput
                                    label="Website"
                                    id="Website"
                                    ref={this.supplierInputs.website}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Telephone"
                                    id="Telephone"
                                    ref={this.supplierInputs.phone_number}
                                />
                            </FormGroup>
                            <Row>
                                <Col xl="6">
                                    <FormGroup>
                                        <Label for="framework_agreement_attachment">FRAMEWORK AGM File</Label>

                                        <TextInput
                                            id="framework_agreement_attachment"
                                            placeholder="Approval attachment"
                                            ref={this.supplierInputs.framework_agreement_attachment}
                                            disabled
                                            class="upload-text-input"
                                        />

                                        <label className="uploadButton" onChange={(e) => this.addFile("framework_agreement_attachment", e)}>
                                            <input type="file" accept="application/pdf" />
                                            <FontAwesomeIcon icon={faPlus} />
                                        </label>

                                        <label className="deleteButton" onClick={(e) => this.fileDelete("framework_agreement_attachment", e)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col xl="6">
                                    <FormGroup>
                                        <DateInput
                                            label="FRAMEWORK AGM Date"
                                            id="frameworkagm-date"
                                            ref={this.supplierInputs.framework_agreement_date}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl="6">
                                    <FormGroup>
                                        <Label for="bonus_agreement_attachment">BONUS AGM File</Label>

                                        <TextInput
                                            id="bonus_agreement_attachment"
                                            placeholder="Approval attachment"
                                            ref={this.supplierInputs.bonus_agreement_attachment}
                                            disabled
                                            class="upload-text-input"
                                        />

                                        <label className="uploadButton" onChange={(e) => this.addFile("bonus_agreement_attachment", e)}>
                                            <input type="file" accept="application/pdf" />
                                            <FontAwesomeIcon icon={faPlus} />
                                        </label>

                                        <label className="deleteButton" onClick={(e) => this.fileDelete("bonus_agreement_attachment", e)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col xl="6">
                                    <FormGroup>
                                        <DateInput
                                            label="BONUS AGM Date"
                                            id="bonusagm-date"
                                            ref={this.supplierInputs.bonus_agreement_date}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl="12">
                                    <Label for="code_of_conduct">Code of Conduct File</Label>

                                    <TextInput
                                        id="code_of_conduct"
                                        placeholder="Code of Conduct"
                                        ref={this.supplierInputs.code_of_conduct}
                                        disabled
                                        class="upload-text-input"
                                    />

                                    <label className="uploadButton" onChange={(e) => this.addFile("code_of_conduct", e)}>
                                        <input type="file" accept="application/pdf" />
                                        <FontAwesomeIcon icon={faPlus} />
                                    </label>

                                    <label className="deleteButton" onClick={(e) => this.fileDelete("code_of_conduct", e)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </label>
                                </Col>
                            </Row>

                        </Form>

                        <ModalFooter>
                            <Button outline color="secondary" onClick={this.edit}>Cancel</Button>
                            {infoSaveButton}
                        </ModalFooter>
                    </ModalBody >
                </Modal >
            )
        }

        let addModal;
        if (this.state.tableName === 'addMill') {
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add mill</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="Name"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={this.state.countries}
                                    label="Country"
                                    id="Country"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.country_id}
                                />

                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="City"
                                    id="City"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.city}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={this.state.qStatuses}
                                    label="Quality status"
                                    id="quality_status_id"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.quality_status_id}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={MillModel.typeDropdownItems()}
                                    label="Type"
                                    id="quality_status_id"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.type}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput

                                    listItems={MillModel.amlClassDropdownItems()}
                                    label="AML Class"
                                    id="aml_class"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.aml_class}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DateInput
                                    label="AML since"
                                    id="aml_since"
                                    onBlur={this.formHandlingUtilMill.getValidateHandle()}
                                    ref={this.millInputs.aml_since}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={() => {
                                this.setState(prevState => ({
                                    modalAdd: !prevState.modalAdd,
                                    currentModelAdd: false,
                                    tableName: "mill"
                                }));
                            }}>Cancel</Button>
                            <Button outline color="primary" onClick={this.formHandlingUtilMill.getSubmitHandle()}>Add</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        } else if (this.state.tableName === 'addSupplierContact') {
            addModal = (
                <Modal centered size="md" isOpen={this.state.modalAdd} fade={false} toggle={this.add}>
                    <ModalHeader toggle={this.add}>Add supplier contact</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <TextInput
                                    label="Name"
                                    id="name"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Job Title"
                                    id="jobtitle"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.job_title}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="E-mail"
                                    id="email"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.email}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Mobile phone"
                                    id="mobilephone"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.mobile_phone}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Sales office"
                                    id="salesoffice"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.sales_office}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextInput
                                    label="Landline"
                                    id="landline"
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.phone}
                                />
                            </FormGroup>
                            <FormGroup
                                style={{ display: "none" }}>
                                <TextInput
                                    label="Supplier id"
                                    id="supplier_id"
                                    value={this.props.match.params.id}
                                    onBlur={this.formHandlingUtilContact.getValidateHandle()}
                                    ref={this.contactInputs.supplier_id}
                                />
                            </FormGroup>
                        </Form>
                        <ModalFooter>
                            <Button outline color="secondary" onClick={() => this.setState(prevState => ({
                                modalEdit: !prevState.modalEdit,
                                currentModalEdit: false,
                                tableName: "supplier_contact"
                            }))}>Cancel</Button>
                            <Button outline color="primary" onClick={this.formHandlingUtilContact.getSubmitHandle()}>Add</Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )
        }

        let deleteModal;
        if (this.state.tableName === 'suppliercontact') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
                    <ModalHeader toggle={this.toggleDelete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this contact?
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        } else if (this.state.tableName === 'supplierinfo') {
            deleteModal = (
                <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
                    <ModalHeader toggle={this.delete}>{this.state.currentModelDelete ? this.state.currentModelDelete.name : null}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this supplier?
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.toggleDelete}>Cancel</Button>
                        <Button outline color="primary" onClick={this.delete}>Delete</Button>
                    </ModalFooter>
                </Modal>
            )
        }

        let fileDeleteModal;
        fileDeleteModal = (
            <Modal centered size="md" isOpen={this.state.modalFileDelete} fade={false} toggle={() => this.fileDelete}>
                <ModalHeader toggle={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Delete file</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this?
                    </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFileDelete: !this.state.modalFileDelete })}>Cancel</Button>
                    <Button outline color="primary" onClick={(e) => this.removeFile(this.state.modalFileDeleteAttr, this.state.modalFileDeleteEvent)}>Delete</Button>
                </ModalFooter>
            </Modal>
        )

        let fileModal;
        let pdfFile = <embed src={this.state.currentModelFile.file} type="application/pdf" />

        fileModal = (
            <Modal centered size="xl" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
                <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile.title ? this.state.currentModelFile.title : 'Attachment'}</ModalHeader>
                <ModalBody>
                    {pdfFile}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )

        let addButton;
        if (Identity.id === this.supplier.manager_id) {
            addButton = (<div className="add-new"><Button onClick={() => this.add('', 'addMill')} color="primary"><FontAwesomeIcon icon={faPlus} /> Add mill</Button></div>)
        }

        let supplierInfoButton;
        if (Identity.id === this.supplier.manager_id) {
            supplierInfoButton = (
                <>
                    <li><Link to="#" onClick={() => this.edit(supplierInfoList, 'supplierinfo')} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link></li>
                    <li><Link to="#" onClick={() => this.toggleDelete(supplierInfoList, 'supplierinfo')} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link></li>
                </>
            )
        }

        let supplierContactButton;
        if (Identity.id === this.supplier.manager_id) {
            supplierContactButton = (<li><Link to="#" onClick={() => this.add('', 'addSupplierContact')} className="table-edit add"><FontAwesomeIcon icon={faPlus} /></Link></li>)
        }

        return (
            <>
                <Container fluid className="page-content" >

                    <div className="title-box">
                        <h1 className="page-title">{this.supplier.name ? this.supplier.name : titleLoader}</h1>
                        {addButton}
                    </div>

                    <Row>
                        <Col xl="4">
                            <div className="element-box">
                                <h2 className="supplier">Supplier info</h2>
                                <ul className="options">
                                    {supplierInfoButton}
                                </ul>
                                <Table hover responsive>
                                    <tbody>
                                        {supplierInfo}
                                    </tbody>
                                </Table>
                                <Link to={"/meetings/" + this.props.match.params.id} ><Button color="block" className="mt-1 box">Go to supplier meeting schedule</Button></Link>


                            </div>
                        </Col>

                        <Col xl="8">
                            <div className="element-box">
                                <h2 className="supplier">Supplier contact</h2>
                                <ul className="options">
                                    {supplierContactButton}
                                </ul>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">E-mail</th>
                                            <th scope="col">Sales office</th>
                                            <th scope="col">Mobile phone</th>
                                            <th scope="col">Landline</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {supplierContact}
                                    </tbody>
                                </Table>
                            </div>

                        </Col>
                    </Row>

                    <h1 className="page-title">Mill <i>({this.state.options.length})</i></h1>

                    <div className="form-group">
                        <Select
                            inputId="e1"
                            styles={reactSelectStyle}
                            value={this.state.selectedMill}
                            onChange={this.handleSelectMill.bind(this)}
                            placeholder="Select Mill"
                            options={this.state.options}
                            classNamePrefix="react-select bodyselect"
                            menuPlacement="auto"
                        />
                        <small id="SelectMill" className="form-text text-muted">Select supplier mill</small>
                    </div>

                    <Row>

                        {this.state.mill_id != null ? <Mill id={this.state.mill_id} manager={this.supplier.manager_id} /> : null}


                    </Row>

                    {editModal}
                    {addModal}
                    {deleteModal}
                    {fileDeleteModal}
                    {fileModal}

                </Container >
            </>
        );
    }
}
